window.addEventListener('DOMContentLoaded', () => {
    let screenWidth = window.innerWidth;
    const handleMiniCart = document.querySelector('.cart-btn');
    const miniCartBody = document.querySelector('.minicart-body');
    const closeMiniCart = document.querySelector('.close-mini-cart-btn');
    const navBarToggle = document.querySelector('.navbar-toggle');
    const megaMenuWrapper = document.querySelector('.megamenu-wrapper');
    const pageOverlay = document.querySelector('.body-cover');
    const body = document.getElementsByTagName('body')[0];
    const handleSizeGuide = document.querySelector('.size-guide-trigger');
    const sizeGuidePopup = document.querySelector('.size-guide');
    const closeSizeGuide = document.querySelector('.close-size-guide-btn');
    const burgerMenu = document.querySelector('.burger-menu');
    const megamenuStyleDev = document.querySelector('.megamenu-style-dev');

    if (burgerMenu) {
        burgerMenu.addEventListener('click', function handleClick(event) {
            event.preventDefault();
            body.classList.toggle('menu-is-open');
            this.classList.toggle('active');
            megaMenuWrapper.classList.toggle('so-megamenu-active');
            megamenuStyleDev.classList.toggle('active');
        });
    }

    if (handleMiniCart) {
        handleMiniCart.addEventListener('click', function handleClick(event) {
            body.classList.add('cart-is-open');
            body.classList.remove('menu-is-open');
            burgerMenu.classList.remove('active');
            megaMenuWrapper.classList.remove('so-megamenu-active');
            megamenuStyleDev.classList.remove('active');
            miniCartBody.classList.toggle('open');
        });
    }

    if (closeMiniCart) {
        closeMiniCart.addEventListener('click', function handleClick(event) {
            event.preventDefault();
            miniCartBody.classList.remove('open');
            body.classList.remove('cart-is-open');
            body.classList.remove('menu-is-open');
        });
    }

    if (closeSizeGuide) {
        closeSizeGuide.addEventListener('click', function handleClick(event) {
            event.preventDefault();
            if (sizeGuidePopup) {
                sizeGuidePopup.classList.remove('active');
            }
            body.classList.remove('size-guide-is-open');
        });
    }

    if (handleSizeGuide) {
        handleSizeGuide.addEventListener('click', function handleClick(event) {
            event.preventDefault();
            body.classList.remove('cart-is-open');
            body.classList.remove('menu-is-open');
            body.classList.toggle('size-guide-is-open');
            if (sizeGuidePopup){
                sizeGuidePopup.classList.toggle('active');
            }
        });
    }

    if (pageOverlay) {
        pageOverlay.addEventListener('click', function handleClick(event) {
            body.classList.remove('cart-is-open');
            body.classList.remove('menu-is-open');
            burgerMenu.classList.remove('active');
            megaMenuWrapper.classList.remove('so-megamenu-active');
            megamenuStyleDev.classList.remove('active');
            body.classList.remove('size-guide-is-open');
            miniCartBody.classList.remove('open');
            if (sizeGuidePopup) {
                sizeGuidePopup.classList.remove('active');
            }
        });
    }

    if (navBarToggle) {
        navBarToggle.addEventListener('click', function handleClick(event) {
            body.classList.remove('cart-is-open');
            miniCartBody.classList.remove('open');
            if(screenWidth <= 1200) {
                let theSelector = $(this).parent();
                if (theSelector.hasClass('active-show')) {
                    theSelector.removeClass('active-show');
                    theSelector.removeClass('click');
                }
                else {
                    $('ul.megamenu > li.with-sub-menu').removeClass('active-show');
                    theSelector.addClass('active-show');
                    theSelector.addClass('click');
                }
            }
        });
    }

});