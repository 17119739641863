window.isTouchDevice = function () {
    return true == ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch));
};

window.screen = function () {
    var state = 'click';
    if (isTouchDevice() === true) {
        state = 'touchstart';
    }
    return {
        h: jQuery(window).height(),
        w: jQuery(window).width(),
        scroll: jQuery(window).scrollTop(),
        pointerAction: state,
    };
};

$(document).ready(function () {
    $('.smoothScroll').on(screen().pointerAction, function (e) {
        e.preventDefault();
        var header = document.getElementsByTagName('header')[0].offsetHeight;
        var hh;
        if (screen().w < 991) {
            hh = $($(this).attr('href')).offset().top - header;
        } else {
            hh = $($(this).attr('href')).offset().top;
        }
        $('html, body').animate(
            {
                scrollTop: hh,
            },
            300,
            'linear'
        );
    }); //smoothScroll

    // Header Sticky
    const header = document.querySelector('header');
    const menuItem = document.querySelector('.with-sub-menu');
    const body = document.querySelector('body');
    const currentCategoryId = $('#_category-id').val() ?? null;
    window.addEventListener('scroll', () => {
        const scroll = window.pageYOffset;
        if (scroll > 200) {
            header.classList.add('sticky-menu');
            if ((body.classList.contains('page_category') || (body.classList.contains('page_voucher'))) && ($(window).width() > 991)) {
                menuItem.classList.add('active-show', 'click')
            }

            if(currentCategoryId != null){
                $('.main-menu-'+ currentCategoryId).addClass('active');
            }

            if(body.classList.contains('page_voucher')){
                $('.giftcard-menu-item').addClass('active');
            }

        } else {
            header.classList.remove('sticky-menu');
            if ((body.classList.contains('page_category') || (body.classList.contains('page_voucher'))) && ($(window).width() > 991)) {
                menuItem.classList.remove('active-show', 'click')
            }

            $('.main-menu').removeClass('active');

            if(body.classList.contains('page_voucher')){
                $('.giftcard-menu-item').removeClass('active');
            }
        }
    });

    // Functions
    const dynamicCSS = (selector, target, property) => {
        let value = target.offsetHeight;
        selector.style.setProperty(property, `${value}px`);
        window.addEventListener('resize', () => {
            value = target.offsetHeight;
            selector.style.setProperty(property, `${value}px`);
        });
    };

    dynamicCSS( document.querySelector('body'), document.querySelector('header'), '--headerHeight');

    let screenHeight = window.innerHeight;
    document.querySelector('body').style.setProperty('--screenHeight', screenHeight + 'px');
    window.addEventListener('resize', () => {
        const screenHeight = window.innerHeight;
        document.querySelector('body').style.setProperty('--screenHeight', screenHeight + 'px');
    });

});
