// import Swiper JS
import Swiper, { Autoplay, EffectFade, Navigation, Controller, Pagination } from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

window.addEventListener('DOMContentLoaded', () => {

    //Best Sellers - Homepage
    const bestSellers = new Swiper('.bestSellers', {
        modules: [Pagination, Autoplay],
        slidesPerView: 1,
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            580: {
                slidesPerView: 2,
            },
            992: {
                loop: false,
                slidesPerView: 3,
            },
            1440: {
                loop: false,
                slidesPerView: 3,
            },
        },
    });

    //Product Images - Product page

    // breakpoint where swiper will be destroyed
    // and switches to a dual-column layout
    const breakpoint = window.matchMedia( '(min-width:768px)' );
    // keep track of swiper instances to destroy later
    let productSlider;

    const breakpointChecker = function() {
        // if larger viewport and multi-row layout needed
        if ( breakpoint.matches === true ) {
            // clean up old instances and inline styles when available
            if ( productSlider !== undefined ) productSlider.destroy( true, true );
            // or/and do nothing
            return;
            // else if a small viewport and single column layout needed
        } else if ( breakpoint.matches === false ) {
            // fire small viewport version of swiper
            return enableSwiper();
        }
    };
    const enableSwiper = function() {
        const productSlider = new Swiper('.product-images', {
            modules: [Pagination, Autoplay],
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination.swiper-pagination-products',
                clickable: true,
            }
        });

        const slideToFirst = document.querySelector("#go-to-first-image");
        if (slideToFirst){
            slideToFirst.addEventListener("click", () => {
                productSlider.slideTo(0);
            });

        }
    };
    // keep an eye on viewport size changes
    breakpoint.addListener(breakpointChecker);
    // kickstart
    breakpointChecker();

    //Related products - Product page
    const relatedProducts = new Swiper('.relatedProducts', {
        modules: [Pagination, Autoplay],
        slidesPerView: 1,
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination.swiper-pagination-related',
            clickable: true,
        },
        breakpoints: {
            580: {
                slidesPerView: 2,
            },
            992: {
                loop: false,
                slidesPerView: 3,
            },
            1440: {
                loop: false,
                slidesPerView: 3,
            },
        },
    });

});

