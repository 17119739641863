import { dynamicCSS } from './common';

window.addEventListener('DOMContentLoaded', () => {
    jQuery('.checkout-left, .checkout-right').theiaStickySidebar({
        additionalMarginTop: 50,
    });

    // Header Sticky
    const checkoutLogo = document.querySelector('#d_logo a');
    if (window.innerWidth < 992 && checkoutLogo) {
        window.addEventListener('scroll', () => {
            const scroll = window.pageYOffset;
            scroll > 50 ? checkoutLogo.classList.add('scrolled') : checkoutLogo.classList.remove('scrolled');
        });
    }

    // add dynamic height to the checkout page
    document.querySelector('.checkout-container') &&
        document.querySelector('#d_logo') &&
        dynamicCSS(document.querySelector('.checkout-container'), document.querySelector('#d_logo'), '--header-height');
});
