$(document).ready(function () {
    $('.filters-btn').click(function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('.products-filters').toggleClass('active');
        $('body').toggleClass('category-filters-open');
    });
    $('.close-filters').click(function (e) {
        e.preventDefault();
        $('.products-filters').removeClass('active');
        $('body').removeClass('category-filters-open');
    });

    $('.bf-attr-block').click(function (e) {
        $(this).siblings().removeClass('bf-expanded-parent');
        $(this).siblings().find('.bf-sliding').removeClass('bf-expanded');
    });

});
