document.addEventListener('DOMContentLoaded', () => {
    const searchText = document.querySelector('.search-text');
    const searchInput = document.querySelector('.search-input');
    const closeSearch = document.querySelector('.close-search-btn');

    if (searchText) {
        searchText.addEventListener('click', (e) => {
            e.preventDefault();
            searchInput.classList.toggle('active');
            searchText.classList.add('hide');
        });
    }

    if (closeSearch) {
        closeSearch.addEventListener('click', (e) => {
            e.preventDefault();
            searchInput.classList.remove('active');
        });
    }

});