import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

gsap.registerPlugin(ScrollTrigger);

window.addEventListener('DOMContentLoaded', () => {
    // fade in animation
    const fadeIn = document.querySelectorAll('.fade-in');
    if (fadeIn.length) {
        fadeIn.forEach((el) => {
            const fadeTl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top 65%',
                },
            });
            fadeTl.fromTo(el, { yPercent: 5, opacity: 0 }, { yPercent: 0, opacity: 1, duration: 1, ease: 'ease' });
        });
    }

    // fade in children elements animation
    const fadeChildren = document.querySelectorAll('.fade-children');
    if (fadeChildren.length) {
        fadeChildren.forEach((el) => {
            const showTl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top 65%',
                },
            });
            showTl.fromTo(el.children, { y: 20, opacity: 0 }, { y: 0, opacity: 1, duration: 1, stagger: 0.3, ease: 'ease' });
        });
    }

    // spin clockwise animation
    const spinClockwise = document.querySelectorAll('.spin-clockwise');
    if (spinClockwise.length) {
        spinClockwise.forEach((el) => {
            const spinTl = gsap.timeline({
                scrollTrigger: {
                    trigger: 'body',
                    start: 'top top',
                    scrub: true,
                },
            });
            spinTl.fromTo(el, { rotation: 0 }, { transformOrigin: 'center', rotation: 360, ease: 'ease' });
        });
    }

    // spin counter clockwise animation
    const spinCounterClockwise = document.querySelectorAll('.spin-counter-clockwise');
    if (spinCounterClockwise.length) {
        spinCounterClockwise.forEach((el) => {
            const spinTl = gsap.timeline({
                scrollTrigger: {
                    trigger: 'body',
                    start: 'top top',
                    scrub: true,
                },
            });
            spinTl.fromTo(el, { rotation: 0 }, { transformOrigin: 'center', rotation: -360, ease: 'ease' });
        });
    }
});
