import './style.scss';

import './scripts/header';
import './scripts/footer';
import './scripts/minicart';
import './scripts/filterButtons';
import './scripts/checkout';
import './scripts/sliders';
import './scripts/common';
import './scripts/product';

import './scripts/animations';
