import Cookies from 'js-cookie';

$(document).ready(function () {
    $( ".newsletter-popup-close" ).click(function(e) {
        e.preventDefault();
        setTimeout(function () {
            $(".newsletter-popup").remove();
            $(".body-cover").removeClass( 'active');
            Cookies.set('GlobalNewsletterPromo', 'hidden', {expires: 2})
        }, 500);
    });
    $('#subscribe_hidden').on('change', function () {
        if ($(this).val() == 1) {
            setTimeout(function () {
                $(".newsletter-popup").remove();
                $(".body-cover").removeClass( 'active');
                Cookies.set('GlobalNewsletterPromo', 'hidden', {expires: 2})
            }, 2000);
        }
    })
    $('.body-cover').click(function() {
        setTimeout(function () {
            $(".newsletter-popup").remove();
            $(".body-cover").removeClass( 'active');
            Cookies.set('GlobalNewsletterPromo', 'hidden', {expires: 2})
        }, 500);
    });
    let newsletterCookie = Cookies.get('GlobalNewsletterPromo');
    let cookieconsentStatus = Cookies.get('cookieconsent_status');
    if (newsletterCookie === 'hidden') {
        $(".newsletter-popup").remove();
        $(".body-cover").remove( 'active');
    } else {
        if(cookieconsentStatus === 'allow'){
            $(".newsletter-popup").css("display", "flex");
            $(".body-cover").addClass( 'active');
            $('.cc-revoke.cc-bottom').remove()
        }
    }


    $('#product-category, #product-search, #product-manufacturer').on('click','.load-more a', function () {
        var url = $(this).attr('href');
        if (typeof xhr !== 'undefined') {
            xhr.abort()
        }
        let xhr = $.ajax({
            url: url + '&loadmore=1',
            type: 'get',
            data: {"banner_key_position" : $('.single-banner').length},
            dataType: 'json',
            beforeSend: function() {
                // $('body').append('<span class="basel-spinner ajax-call"></span>');
                $('.flex-row.category-pagination').hide()
                $('.product-list.product-holder').fadeTo( 0, 0.4 );
            },
            success: function(json) {

                $('.product-holder').append(json.products);
                $('.category-pagination .pagination-div').html(json.pagination);
                $('.results-info').html(json.results_info);

                setTimeout(function () {
                    $('.flex-row.category-pagination').show()
                    $('.product-caption').css('display', 'flex');
                }, 300)

                // $('#videoPosition').val(json.video_position);
                // $('#countVideo').val(json.countVideos);
                window.history.pushState({}, document.title, url);
                $('.product-list.product-holder').fadeTo(400, 1);
            }
        });
        return false;
    })

    $(window).scroll(function() {
        if($('.results-info').length){
            var hT = $('.results-info').offset().top - 250,
                hH = $('.results-info').outerHeight() - 250,
                wH = $(window).height(),
                wS = $(this).scrollTop();

            if (wS > (hT+hH-wH) && (hT > wS) && (wS+wH > hT+hH) && $.active === 0){
                $('.load-more a').trigger('click');
            }
        }

    });
});
