require("@fancyapps/fancybox");
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
$(document).ready(function () {
    $('.product-tab-heading').click(function (e) {
        e.preventDefault();
        if ($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active');
        } else {
            $('.product-tab').removeClass('active');
            $(this).parent().addClass('active');
        }
    });
});